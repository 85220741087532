<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("dataStructures.stepConfig") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectDataStructures' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          v-if="dataStructure.config.type === 0 || dataStructure.config.type"
          class="btn btn-primary"
          :disabled="
            dataStructure.is_locked || dataStructure.is_standard_data_structure
          "
          @click="updateDataStructure"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <ConfigCSV
        v-if="dataStructure.config && dataStructure.config.type === 0"
        ref="step-basic-config-0"
        :initial-config="dataStructure.config"
      />
      <ConfigXML
        v-else-if="dataStructure.config && dataStructure.config.type === 1"
        ref="step-basic-config-1"
        :initial-config="dataStructure.config"
      />
      <ConfigJSON
        v-else-if="dataStructure.config && dataStructure.config.type === 2"
        ref="step-basic-config-2"
        :initial-config="dataStructure.config"
      />
      <div v-if="[0, 1, 2].includes(dataStructure.config.type)" class="mt-3">
        <FormHelper
          v-model="dataStructure.config"
          :form="fileBasedConfigurationForm"
          :config="configurationFormConfig"
        />
      </div>
      <FormHelper
        v-model="dataStructure.config"
        :form="configurationForm"
        :config="configurationFormConfig"
      />
    </div>
  </div>
</template>

<script>
import ConfigCSV from "@/components/DataStructures/Configs/CSV.vue";
import ConfigXML from "@/components/DataStructures/Configs/XML.vue";
import ConfigJSON from "@/components/DataStructures/Configs/JSON.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: {
    FormHelper,
    ConfigCSV,
    ConfigXML,
    ConfigJSON
  },
  props: ["dataStructure", "isBusy"],
  data() {
    return {
      fileBasedConfigurationForm: [
        {
          type: "select",
          label: "onEmptyData",
          name: "onEmptyData",
          options: [
            {
              label: "-",
              value: ""
            },
            {
              label: "errorHandling.warning",
              value: "warn"
            },
            {
              label: "errorHandling.error",
              value: "error"
            }
          ],
          hint: "onEmptyDataTooltip"
        },
        {
          type: "checkbox",
          label: "checkStructure",
          name: "checkStructure",
          help: "checkStructureTooltip"
        }
      ],
      configurationForm: [
        {
          type: "select",
          label: "onInvalidData",
          name: "onInvalidData",
          options: [
            {
              label: "errorHandling.warning",
              value: ""
            },
            {
              label: "errorHandling.error",
              value: "error"
            }
          ],
          default: null
        }
      ],
      configurationFormConfig: {
        snippetPrefix: "dataStructures"
      }
    };
  },
  mounted() {
    // Parameter is not checked correctly in core - parameter has to be switched
    this.dataStructure.config.forceEncoding =
      !this.dataStructure.config.forceEncoding;
    if (!Object.hasOwn(this.dataStructure.config, "onInvalidData")) {
      this.dataStructure.config.onInvalidData = "";
    }
  },
  methods: {
    updateDataStructure() {
      this.dataStructure.config = this.getConfig();
      this.$emit("save");
    },
    getConfig() {
      let config =
        this.$refs[
          "step-basic-config-" + this.dataStructure.config.type
        ].fetchConfig();
      config.onEmptyData = this.dataStructure.config.onEmptyData;
      config.onInvalidData = this.dataStructure.config.onInvalidData;
      config.checkStructure = this.dataStructure.config.checkStructure;
      return config;
    }
  }
};
</script>
