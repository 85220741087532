var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[(_vm.isBusy)?_c('v-progress-linear',{staticClass:"mt-4",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body"},[(_vm.dataStructure.is_locked)?_c('AlertIsLocked'):_vm._e(),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("dataStructures.baseData"))+":")])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_c('div',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("dataStructures.label")))])]),_c('div',{staticClass:"col-md-9 col-lg-6 col-xl-4"},[_c('b-form-input',{staticClass:"form-control",class:{
            'is-invalid': _vm.$v.dataStructure.label.$error,
            'is-valid':
              _vm.$v.dataStructure.label.required &&
              _vm.$v.dataStructure.label.minLength
          },attrs:{"trim":"","disabled":_vm.dataStructure.is_locked ||
            _vm.dataStructure.is_standard_data_structure},on:{"input":function($event){return _vm.$v.dataStructure.label.$touch()}},model:{value:(_vm.dataStructure.label),callback:function ($$v) {_vm.$set(_vm.dataStructure, "label", $$v)},expression:"dataStructure.label"}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3 col-form-label"},[_c('div',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("dataStructures.projects")))])]),_c('div',{staticClass:"col-md-9 col-lg-6 col-xl-4"},[_c('v-select',{staticClass:"form-control",class:{
            'is-invalid': _vm.$v.dataStructure.projects.$error,
            'is-valid': _vm.$v.dataStructure.projects.required
          },attrs:{"items":_vm.projects,"item-text":"name","item-value":"id","menu-props":{ offsetY: true },"disabled":_vm.dataStructure.is_standard_data_structure,"multiple":""},on:{"input":function($event){return _vm.$v.dataStructure.projects.$touch()}},model:{value:(_vm.dataStructure.projects),callback:function ($$v) {_vm.$set(_vm.dataStructure, "projects", $$v)},expression:"dataStructure.projects"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }