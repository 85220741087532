<template>
  <div>
    <Header
      :title="$t('dataStructures.dataStructures').toString()"
      :items="headerButtons"
      @createDataStructure="routeToCreate"
    >
      <template v-if="!isPresetProject && isDevPresetVersion" #afterItems>
        <file-upload
          ref="upload"
          v-b-popover.hover.top="$t('dataStructures.importDataStructure')"
          accept="application/json,.yml,.yaml"
          class="btn btn-outline-primary btn-hover ml-2"
          extensions="json,yaml,yml"
          @input-file="importDataStructure"
        >
          <i class="fa-light fa-file-import"></i>
        </file-upload>
      </template>
    </Header>
    <TableHelper
      ref="table"
      :items="dataStructures"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="true"
      :filterable="computedFilterable"
      :filterable-field-blacklist="filterableFieldBlacklist"
      default-order-by="label"
      :exportable="true"
      loading-key="loadDataStructures"
      @reload-data="loadDataStructures"
      @openEditor="openEditor"
      @edit="editDataStructure"
      @export="exportDataStructure"
      @showVersions="showVersions"
      @delete="deleteDataStructure"
      @export-csv="onExportCsv"
    />

    <BackupModal
      v-model="showVersionsModal"
      :object="selectedDataStructure"
      classname="DataStructure"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import FileUpload from "vue-upload-component";
import DataStructures from "@/components/DataStructures/dataStructures";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";
import _ from "lodash";

export default {
  components: { FileUpload, BackupModal, Header, TableHelper },
  data() {
    return {
      dataStructures: [],
      selectedDataStructure: null,
      showVersionsModal: false,
      fields: [
        {
          key: "label",
          label: this.$t("dataStructures.label"),
          sortable: true
        },
        {
          key: "version",
          label: this.$t("table.version"),
          sortable: true
        },
        {
          key: "primary",
          label: this.$t("table.primary"),
          sortable: true
        },
        {
          key: "updated_by_user.full_name",
          sortable: true,
          label: this.$t("general.resourceOwner"),
          thStyle: { width: "300px" }
        },
        {
          key: "updated_at",
          formatter: "formatDate",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "200px" }
        }
      ],
      actions: [
        {
          key: "editor",
          icon: this.$store.getters["config/icons"].editor,
          emit: "openEditor",
          tooltip: this.$t("dataStructures.openEditor")
        },
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("dataStructures.configure")
        },
        {
          key: "export",
          icon: this.$store.getters["config/icons"].exportEntity,
          emit: "export",
          tooltip: this.$t("dataStructures.export")
        },
        {
          key: "versions",
          icon: this.$store.getters["config/icons"].version,
          emit: "showVersions"
        },
        {
          key: "delete",
          icon: this.$store.getters["config/icons"].delete,
          emit: "delete",
          tooltip: this.$t("dataStructures.deleteTitle")
        }
      ],
      // Meta info
      meta: {},
      // Filterable fields
      filterable: {},
      filterableFieldBlacklist: [
        "id",
        "project_id",
        "owner_type",
        "not_owner_type",
        "technical_name"
      ]
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters("instance", ["instance"]),
    ...mapGetters([
      "selectedProject",
      "isPresetProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          title: this.$t("dataStructures.create"),
          icon: this.$store.getters["config/icons"].add,
          emit: "createDataStructure"
        }
      ];
      return this.isPresetProject || !this.isDevPresetVersion
        ? []
        : headerButtons;
    },
    computedFilterable: function () {
      return Object.assign(this.filterable, {
        standardDs: "bool"
      });
    }
  },
  watch: {
    selectedProject: function () {
      this.loadDataStructures();
    },
    selectedPresetVersion: function () {
      this.loadDataStructures();
    }
  },
  mounted() {
    this.loadDataStructures();
  },
  methods: {
    loadDataStructures(payload = {}) {
      this.dataStructures = [];

      if (this.instance?.isPresetInstance && !this.selectedPresetVersion) {
        return;
      }

      addEventToLoadingQueue({
        key: "loadDataStructures"
      });

      let params = { ...this.params(), ...payload };
      const { filter } = useCurrentProjectFilter();
      filter.value.push({
        key: "not_owner_type",
        op: "equals",
        value: "App\\Models\\DataSet"
      });

      let paramsCopy = _.cloneDeep(params);

      if (paramsCopy.filter) {
        // Add filter for technical_name, either is empty or has technical_name (= standard DS)
        const index = paramsCopy.filter.indexOf(
          paramsCopy.filter.find(f => f.key === "standardDs")
        );
        const hasStandardDsFilter = index !== -1;

        if (hasStandardDsFilter) {
          const value = paramsCopy.filter[index].value;
          paramsCopy.filter.splice(index, 1);
          paramsCopy.filter.push({
            key: "technical_name",
            op: value === "true" ? "notEquals" : "IS NULL",
            value: value === "true" ? "" : "null"
          });
        }
      }

      DataStructures.getAll(paramsCopy, filter.value)
        .then(response => {
          removeEventFromLoadingQueue({
            key: "loadDataStructures"
          });

          this.dataStructures = response.data;
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
        })
        .catch(error => {
          this.$error(error);
        });
    },
    editDataStructure(data) {
      this.$router.push({
        name: "projectDataStructuresDetails",
        params: { id: data.item.id }
      });
    },
    exportDataStructure(item) {
      let id = item?.item?.id;
      let name = item?.item?.label;

      addEventToLoadingQueue({ key: "exportDataStructureAction" });
      DataStructures.export(id, "json")
        .then(response => {
          removeEventFromLoadingQueue({
            key: "exportDataStructureAction",
            type: "success",
            prefix: "dataStructure",
            name: "exportSuccess"
          });
          const blob = new Blob([JSON.stringify(response)], {
            type: "application/json"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "[xentral_connect]" + name.replace(/ /g, "_") + "_" + id;
          link.click();
          URL.revokeObjectURL(link.href);
          this.$toast.fire({
            title: this.$t("workflowDesigner.workflowExportSuccess", {
              name: name
            }),
            icon: "success"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    showVersions(data) {
      this.selectedDataStructure = data.item;
      this.showVersionsModal = true;
    },
    deleteDataStructure(dataStructure) {
      let id = dataStructure.item.id;
      let name = dataStructure.item.label;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataStructures.deleteTitle"),
          text: this.$t("dataStructures.deleteText", { name: name }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#5b64ee"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.deleteDataStructureAction(id);
        });
    },
    deleteDataStructureAction(id) {
      addEventToLoadingQueue({
        key: "deleteDataStructures"
      });
      DataStructures.delete(id)
        .then(response => {
          if (response.data.success) {
            removeEventFromLoadingQueue({
              key: "deleteDataStructures",
              type: "success",
              prefix: "dataStructures",
              name: "deletedSuccess"
            });
            this.loadDataStructures();
            return;
          }
          this.$error(response.data.error);
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue({
            key: "deleteDataStructures"
          });
        });
    },
    routeToCreate() {
      this.$router.push({ name: "projectDataStructuresCreate" });
    },
    importDataStructure(newFile, oldFile) {
      if (newFile && !oldFile) {
        addEventToLoadingQueue({ key: "loadingFile" });

        let importData = new FormData();
        importData.append("file", newFile.file);

        if (this.selectedProject.id) {
          importData.append("project_id", this.selectedProject.id);
        }

        DataStructures.import(importData)
          .then(() => {
            this.loadDataStructures();
            removeEventFromLoadingQueue({
              key: "loadingFile",
              type: "success",
              prefix: "dataStructure",
              name: "dataStructureImportSuccess"
            });
          })
          .catch(error => {
            this.$error(error);
          });
      }
    },
    openEditor(data) {
      this.$router.push({
        name: "projectDataStructuresEditor",
        params: { id: data.item.id }
      });
    },
    onExportCsv(data) {
      DataStructures.exportCsv(data).catch(error => {
        this.$error(error);
      });
    }
  }
};
</script>
