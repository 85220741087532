<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("dynamicModels.previewAndConfiguration") }}:
        </h5>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="">{{ $t("dynamicModels.primaryKey") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="d-flex align-items-center">
          <div class="input-group mr-1">
            <input v-model="primaryKey" type="text" class="form-control" />
          </div>
          <Tooltip :text="$t('dynamicModels.primaryXmlHelp')" />
        </div>
      </div>
    </div>
    <vue-json-pretty
      v-model="selectedNode"
      style="height: 400px"
      highlight-mouseover-node
      :data="items"
      selectable-type="single"
      :virtual="true"
      :virtual-lines="20"
    ></vue-json-pretty>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import Tooltip from "@/components/Tools/Tooltip.vue";
export default {
  components: {
    VueJsonPretty,
    Tooltip
  },
  props: ["items", "primary"],
  data() {
    return {
      selectedNode: null
    };
  },
  computed: {
    primaryKey: function () {
      if (!this.selectedNode && !this.primary) return null;
      if (!this.selectedNode && this.primary) return this.primary;
      let primaryKey = this.selectedNode.split(".");
      primaryKey.shift();
      primaryKey = primaryKey.join(".");
      this.$emit("primary-changed", primaryKey);
      return primaryKey;
    }
  }
};
</script>
