<template>
  <FormHelper
    ref="form"
    v-model="formValues"
    :config="formConfig"
    :form="form"
    @change="onFormFieldChange"
  />
</template>

<script>
import { mapGetters } from "vuex";
import DataStructures from "@/components/DataStructures/dataStructures";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper },
  data() {
    return {
      formConfig: {
        title: this.$t("dataStructures.dataStructureBasis"),
        snippetPrefix: "dataStructures",
        enableVariables: false
      },
      formValues: {
        label: "",
        projects: [],
        option: 0,
        template: ""
      },
      dataStructureOption: 0,
      dataStructuresTemplates: [],
      dataStructure: {
        version: 1,
        is_published: false,
        is_locked: false,
        useDataVersioning: false,
        projects: []
      }
    };
  },
  computed: {
    ...mapGetters(["projects", "selectedProject"]),
    form: function () {
      return [
        {
          name: "label",
          label: "label",
          type: "text",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          name: "projects",
          label: "projects",
          type: "multiselect",
          options: this.projects?.length
            ? this.projects.map(project => {
              return {
                label: project.name,
                value: project.id
              };
            })
            : [],
          default: [this.selectedProject.id],
          validations: {
            required: true
          }
        },
        {
          name: "option",
          label: "dataStructureBasisSelect",
          type: "select",
          options: this.getDataStructureOptions()
        },
        {
          name: "template",
          label: "dataStructureBasisSelectOriginal",
          type: "select",
          options: this.getTemplateOptions(),
          validations: {
            required: true
          },
          dependsOn: [
            {
              name: "option",
              values: [3]
            }
          ]
        }
      ];
    }
  },
  watch: {
    "formValues.option": function () {
      delete this.dataStructure["parent_id"];
      delete this.dataStructure["fields"];
      delete this.dataStructure["primary"];
    }
  },
  mounted() {
    this.getDataStructures();
    this.dataStructure.projects = [this.selectedProject.id];
    this.formValues.projects = [this.selectedProject.id];
  },
  methods: {
    getDataStructureOptions() {
      return [
        {
          label: this.$t("dataStructures.dataStructureBasisNew"),
          value: 0
        },
        {
          label: this.$t("dataStructures.dataStructureBasisExisting"),
          value: 3
        },
        {
          label: this.$t("dataStructures.dataStructureBasisFile"),
          value: 2
        }
      ];
    },
    getTemplateOptions() {
      return this.dataStructuresTemplates.map(template => ({
        label: `${template.label} - ${template.type}`,
        value: template.id
      }));
    },
    onFormFieldChange(data) {
      // this function is triggered, when a single field in formHelper changed
      // depending on the field there are some things to do
      if (data.name === "option" && data.value === 3) {
        // if option changed to 3, we have to set the template options
        this.form[3].options = this.getTemplateOptions();
      } else if (data.name === "template") {
        // if template changed, the template data will be applied
        this.setBaseData(data.value);
      } else if (data.name === "label") {
        // set datastructure label
        this.dataStructure.label = data.value;
      } else if (data.name === "projects") {
        // set datastructure projects
        this.dataStructure.projects = data.value;
        this.getDataStructures();
      }

      // validate input
      const formValid =
        this.dataStructure.label?.length > 2 &&
        this.dataStructure.projects.length > 0;

      // pass current data to parent component
      this.$emit(
        "data-structure-changed",
        data.name === "option" ? data.value : this.formValues.option,
        formValid,
        this.dataStructure
      );
    },
    getDataStructures() {
      this.dataStructuresTemplates = [];
      let params = { noPagination: true };
      let filter = [
        {
          key: "project_id",
          op: "equals",
          value: this.dataStructure.projects.length
            ? this.dataStructure.projects
            : this.formValues.projects
        }
      ];
      DataStructures.getAll(params, filter)
        .then(response => {
          let dataStructures = response.data;
          dataStructures.forEach(dataStructure => {
            dataStructure.type = dataStructure.owner_type.includes("DataSet")
              ? "DataSet"
              : "DataStructure";
            this.dataStructuresTemplates.push(dataStructure);
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async setBaseData(id) {
      let selectedStructure = this.dataStructuresTemplates.find(
        item => item.id === id
      );
      if (!selectedStructure) return;

      if (selectedStructure.fields === undefined) {
        await DataStructures.getTemplate(selectedStructure.id).then(
          response => {
            selectedStructure.fields = response.data.fields;
          }
        );
      }

      this.dataStructure.version = selectedStructure.version;
      if (this.dataStructureOption !== 1) {
        this.dataStructure.version++;
      }
      this.dataStructure.primary = selectedStructure.primary;
      this.dataStructure.fields = selectedStructure.fields;
      this.dataStructure.queries = selectedStructure.queries;
      this.dataStructure.parent_id = selectedStructure.id;
    }
  }
};
</script>
