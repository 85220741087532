<template>
  <div>
    <Header
      :show-back-button="true"
      :title="$t('dataStructures.create').toString()"
      :items="headerButtons"
      @back="cancel"
      @startWizard="startWizard"
      @save="saveDataStructure"
    />
    <div class="card card-custom">
      <div class="card-body p-0">
        <StepBasics
          v-if="stepBasics"
          class="ma-10"
          @data-structure-changed="dataStructureChanged"
        />

        <div v-show="!stepBasics">
          <v-stepper v-model="stepper" class="elevation-0">
            <v-stepper-header class="elevation-0 border-bottom px-8">
              <v-stepper-step step="1" :complete="stepper > 1">
                {{ $t("dynamicModels.stepAnalyze") }}
              </v-stepper-step>

              <v-divider />

              <v-stepper-step step="2" :complete="stepper > 2">
                {{ $t("dataStructures.stepConfig") }}
              </v-stepper-step>

              <v-divider />

              <v-stepper-step step="3">
                {{ $t("dynamicModels.stepSave") }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <StepAnalyze
                  ref="step-analyze"
                  :key="componentKey"
                  class="ml-5"
                  :data="data"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <StepBasicConfiguration
                  :key="componentKey"
                  ref="step-basic-configuration"
                  class="ml-5"
                  :analyzed-data="dataStructure"
                  @analyze="reanalyzeFile"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <StepSave
                  :key="componentKey"
                  ref="step-save"
                  class="ml-5"
                  :data-structure="dataStructure"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <v-progress-linear v-if="isBusy" indeterminate />

          <div
            class="my-2 d-flex justify-content-between pa-8 mt-10 border-top"
          >
            <button
              v-if="stepper > 1"
              class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isBusy"
              @click.prevent="previousStep"
            >
              {{ $t("dynamicModels.previous") }}
            </button>
            <button
              v-if="stepper === 3"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isBusy || !isValid"
              @click="saveDataStructure"
            >
              {{ $t("dynamicModels.goToEditor") }}
            </button>
            <button
              v-if="stepper < 3"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 ml-auto"
              :disabled="data.fileType === null || isBusy"
              @click.prevent="nextStep"
            >
              <span v-if="!isStepAnalyze">{{ $t("dynamicModels.next") }}</span>
              <span v-else>
                <i class="fal fa-magnifying-glass" />
                {{ $t("dynamicModels.analyze") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepBasics from "./Components/StepBasics.vue";

import StepAnalyze from "./Components/StepAnalyze.vue";
import StepBasicConfiguration from "./Components/StepBasicConfiguration.vue";
import StepSave from "./Components/StepSave.vue";

import { SET_DATA_STRUCTURE_CREATED } from "@/core/services/store/dataStructure.module";

import { cloneDeep, generateHash } from "@/components/Tools/helperFunctions";
import Header from "@/components/Tools/Header/Header.vue";
import DataStructures from "@/components/DataStructures/dataStructures";

export default {
  components: {
    Header,
    StepBasics,
    StepAnalyze,
    StepBasicConfiguration,
    StepSave
  },
  props: {
    jsonData: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      headerButtons: [],
      stepBasics: true,
      option: 0,
      dataStructureFieldTypes: [],
      dataStructure: {
        config: {}
      },

      stepper: 1,
      data: {
        mode: 0,
        file: null,
        text: "",
        fileType: null,
        config: {}
      },
      analyzedData: {},
      componentKey: 1,
      isBusy: false,
      isValid: false
    };
  },
  computed: {
    isStepAnalyze: function () {
      return this.stepper === 1;
    },
    isStepBasicConfig: function () {
      return this.stepper === 2;
    }
  },
  watch: {
    option: function () {
      this.setHeaderButtons();
    },
    stepBasics: function () {
      this.setHeaderButtons();
    }
  },
  mounted() {
    this.loadFieldTypes();
    this.setHeaderButtons();
  },
  methods: {
    setHeaderButtons() {
      this.headerButtons = [];
      if (this.option !== 2) {
        this.headerButtons.push({
          type: "button",
          title: this.$t("general.next"),
          emit: "save",
          disabled: !this.isValid
        });
      } else if (this.stepBasics) {
        this.headerButtons.push({
          type: "button",
          title: this.$t("dataStructures.startWizard"),
          emit: "startWizard"
        });
      }
    },
    loadFieldTypes() {
      DataStructures.getFieldTypes().then(response => {
        this.dataStructureFieldTypes = [
          ...response.data.data_fields,
          ...response.data.structure_fields
        ];
      });
    },
    dataStructureChanged(option, valid, dataStructure) {
      this.option = option;
      this.isValid = valid;
      this.dataStructure = dataStructure;
      if (this.dataStructure.config === undefined) {
        this.dataStructure.config = {};
      }
      if (this.headerButtons.length > 0) {
        this.headerButtons[0].disabled = !this.isValid;
      }
    },
    startWizard() {
      this.stepBasics = false;
    },
    previousStep() {
      this.stepper--;
    },
    nextStep() {
      if (this.isStepAnalyze) {
        this.analyzeFile(this.getFormData());
      } else if (this.isStepBasicConfig) {
        this.data.config = this.$refs["step-basic-configuration"].getConfig();
        this.analyzedData.config = this.data.config;
        this.componentKey++;
        this.stepper++;
      }
    },
    analyzeFile(data) {
      this.isBusy = true;
      let stepConfig = this.$refs["step-analyze"].getConfig();
      Object.keys(stepConfig).forEach(key => {
        data.append("config[" + key + "]", stepConfig[key]);
      });
      data.append(
        "config[onEmptyData]",
        this.dataStructure.onEmptyDataOption ?? ""
      );
      data.append(
        "config[checkStructure]",
        !!this.dataStructure.checkStructure
      );
      DataStructures.analyze(data)
        .then(response => {
          this.analyzedData = response;
          if (this.analyzedData.fields.length > 0) {
            this.analyzedData.primary = this.analyzedData.fields[0].name;
          }
          this.isValid = this.analyzedData.fields.length > 0;
          this.setDataStructureData();

          this.componentKey++;
          this.stepper++;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    reanalyzeFile(config) {
      this.isBusy = true;
      this.data.config = config;
      const data = this.getFormData();
      DataStructures.analyze(data)
        .then(response => {
          this.analyzedData.config = response.config;
          this.analyzedData.entries = response.entries;
          this.analyzedData.fields = response.fields;
          this.analyzedData.primary =
            response.primary ?? this.analyzedData.primary;
          // this.componentKey++;
          this.isValid = this.analyzedData.fields.length > 0;
          if (this.analyzedData.fields.length > 0) {
            this.analyzedData.primary = this.analyzedData.fields[0].name;
          }
          this.setDataStructureData();
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    getFormData() {
      let formData = new FormData();
      if (this.data.mode === 0) {
        formData.append("file", this.data.file);
      } else if (this.data.mode === 1) {
        formData.append("string", this.data.text);
      }
      formData.append("type", this.data.fileType);
      Object.keys(this.data.config).forEach(key => {
        formData.append("config[" + key + "]", this.data.config[key]);
      });
      return formData;
    },
    setDataStructureData() {
      this.dataStructure.fields = [];
      if (!this.analyzedData) return;

      this.dataStructure.primary = this.analyzedData.primary;
      this.dataStructure.config = this.analyzedData.config;
      this.dataStructure.config.type = this.analyzedData.type;
      this.dataStructure.entries = this.analyzedData.entries;
      this.analyzedData.fields.forEach(field => {
        field.id = generateHash();
        field.isLeaf = false;
        field.label = field.name;
        field.original_name = field.originalName;
        field.show_in_view = !field.parent && field.type !== "collection";
        field.description = "";
        field.config = [];
        field.parent_id = null;

        const fieldType = this.dataStructureFieldTypes.find(
          f => f.name === field.type
        );
        if (fieldType && fieldType.config) {
          field.config = cloneDeep(fieldType.config);
          field.config.forEach(conf => {
            conf.value = null;
          });
        }

        if (field.parent) {
          const parent = this.dataStructure.fields.find(
            f => f.fullName === field.parent
          );
          if (parent) {
            field.parent_id = parent.id;
          }
        }

        this.dataStructure.fields.push(field);
      });
    },
    saveDataStructure() {
      this.$store.dispatch(SET_DATA_STRUCTURE_CREATED, this.dataStructure);
      this.$router.push({ name: "projectDataStructuresEditor" });
    },
    cancel() {
      this.$router.push({ name: "projectDataStructures" });
    }
  }
};
</script>

<style lang="scss"></style>
