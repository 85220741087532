<template>
  <div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.encoding") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <v-select
            v-model="config.encoding"
            class="form-control"
            :items="encodingOptions"
            item-text="item"
            item-value="item"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('dynamicModels.placeholderSelect')"
          />
          <div class="input-group-append">
            <span class="input-group-text border-0">
              <i class="fal fa-code icon-lg" />
            </span>
          </div>
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.emptyForAutoDetection") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="">{{ $t("dynamicModels.forceEncoding") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="d-flex justify-space-between align-items-center">
          <span class="switch">
            <label>
              <input v-model="config.forceEncoding" type="checkbox" />
              <span></span>
            </label>
          </span>
          <i
            v-b-popover.hover.top="$t('dynamicModels.showForceEncodingHelp')"
            class="fal fa-question-circle ml-1"
          />
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.delimiter") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <input v-model="config.delimiter" type="text" class="form-control" />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.emptyForAutoDetection") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.enclosure") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <input v-model="config.enclosure" type="text" class="form-control" />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.emptyForAutoDetection") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.previewLines") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <input
            v-model="config.previewLines"
            type="number"
            min="1"
            class="form-control"
          />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.defaultTen") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { encodingOptions } from "@/components/Tools/encodingOptions";

export default {
  props: ["initialConfig"],
  data() {
    return {
      config: {
        encoding: "",
        delimiter: "",
        enclosure: "",
        forceEncoding: false
      },
      encodingOptions: encodingOptions
    };
  },
  mounted() {
    this.mapInitialConfig();
  },
  methods: {
    mapInitialConfig() {
      this.config.type = this.initialConfig.type;
      this.config.encoding = this.initialConfig.encoding;
      this.config.delimiter = this.initialConfig.delimiter;
      this.config.enclosure = this.initialConfig.enclosure;
      this.config.forceEncoding = this.initialConfig.forceEncoding;
      this.config.previewLines = this.initialConfig.previewLines ?? 10;
    },
    fetchConfig() {
      let config = {};
      config.type = this.config.type;
      if (this.config.encoding) config.encoding = this.config.encoding;
      if (this.config.delimiter) config.delimiter = this.config.delimiter;
      if (this.config.enclosure) config.enclosure = this.config.enclosure;
      if (this.config.forceEncoding)
        config.forceEncoding = this.config.forceEncoding;
      if (this.config.previewLines)
        config.previewLines = this.config.previewLines;

      return config;
    }
  }
};
</script>
