<template>
  <div>
    <Header
      :title="dataStructure.label"
      :subtitle="subtitle"
      :items="headerButtons"
      :show-back-button="true"
      @back="$router.push({ name: 'projectDataStructures' })"
      @save="updateDataStructure"
      @showVersions="onShowVersions"
    />

    <div class="d-flex">
      <div class="flex-row-auto w-300px">
        <div class="card card-custom">
          <div class="card-body pa-3">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-3"
                    :class="{ active: $route.name === item.route }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-5 client-details">
        <BaseData
          v-if="$route.name === routeBaseData"
          ref="baseData"
          :key="componentKey"
          :data-structure="dataStructure"
          :is-busy="isBusy"
        />

        <Configuration
          v-if="$route.name === routeConfiguration"
          :key="componentKey"
          :data-structure="dataStructure"
          :is-busy="isBusy"
          @save="updateDataStructure"
        />
      </div>
    </div>
    <BackupModal
      v-if="dataStructure"
      v-model="showVersionsModal"
      :object="dataStructure"
      classname="DataStructure"
    />
  </div>
</template>

<script>
import BaseData from "@/components/DataStructures/Details/Components/BaseData.vue";
import Configuration from "@/components/DataStructures/Details/Components/Configuration.vue";
import DataStructures from "@/components/DataStructures/dataStructures";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { mapGetters } from "vuex";
import { useWorkNote } from "@/composables/useWorkNote";

export default {
  components: {
    BackupModal,
    Header,
    BaseData,
    Configuration
  },
  data() {
    return {
      subtitle: "",
      dataStructure: {},
      tabIndex: 0,
      routeBaseData: "",
      routeConfiguration: "",
      routeSQLQueries: "",
      tabNavigation: [],
      componentKey: 1,
      isBusy: false,
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters([
      "selectedPresetVersion",
      "isPresetProject",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerButtons;
      }
      headerButtons.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerButtons;
    }
  },
  watch: {
    $route: function () {
      this.checkRoute();
    },
    selectedPresetVersion: function () {
      this.loadDataStructure();
    }
  },
  mounted() {
    this.loadDataStructure();
  },
  methods: {
    setRoutes() {
      this.routeBaseData = "projectDataStructuresDetailsBaseData";
      this.routeConfiguration = "projectDataStructuresDetailsConfiguration";
      this.routeSQLQueries = "projectDataStructuresDetailsStandardSQLQueries";

      this.tabNavigation = [
        {
          route: this.routeBaseData,
          label: "dataStructures.baseData",
          icon: "fal fa-sliders"
        },
        {
          route: this.routeConfiguration,
          label: "dataStructures.stepConfig",
          icon: "fal fa-gear"
        }
      ];
    },
    checkRoute() {
      if (this.$route.name === "projectDataStructuresDetails")
        this.$router.push({ name: this.routeBaseData });
    },
    loadDataStructure() {
      addEventToLoadingQueue({
        key: "loadDataStructures"
      });
      let id = this.$route.params.id;
      DataStructures.get(id)
        .then(response => {
          this.dataStructure = response.data;

          if (Array.isArray(this.dataStructure.config)) {
            this.dataStructure.config = {};
          }

          if (this.dataStructure.is_standard_data_structure) {
            this.headerButtons[0].disabled = true;
            this.headerButtons[1].disabled = true;
          }

          let subtitleParts = [];
          if (this.dataStructure.updated_by_user?.full_name) {
            subtitleParts.push(this.dataStructure.updated_by_user?.full_name);
          }
          subtitleParts.push(formatDate(this.dataStructure.updated_at));

          this.subtitle = subtitleParts.join(", ");

          this.dataStructure.projects = this.dataStructure.projects.map(
            project => project.id
          );
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          if (
            this.dataStructure.config.checkStructure === undefined &&
            [0, 1, 2].includes(this.dataStructure.config.type)
          ) {
            this.dataStructure.config.checkStructure = false;
          }
          if (
            this.dataStructure.config.onEmptyData === undefined &&
            [0, 1, 2].includes(this.dataStructure.config.type)
          ) {
            this.dataStructure.config.onEmptyData = "";
          }
          this.setRoutes();
          this.checkRoute();
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue({
            key: "loadDataStructures"
          });
        });
    },
    async updateDataStructure() {
      if (this.$route.name === this.routeBaseData) {
        if (!this.$refs.baseData.isValid()) {
          return;
        }
      }

      const { addWorkNote } = useWorkNote();
      const { data, success } = await addWorkNote(this.dataStructure);
      if (!success) {
        return;
      }

      addEventToLoadingQueue({
        key: "updateDataStructures"
      });
      DataStructures.update(data.id, data)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "updateDataStructures",
            type: "success",
            prefix: "dataStructures",
            name: "storeSuccessTitle"
          });

          this.$router.push({ name: "projectDataStructures" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
