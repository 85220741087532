<template>
  <div class="card card-custom">
    <v-progress-linear
      v-if="isBusy"
      class="mt-4"
      indeterminate
      color="primary"
    />
    <div class="card-body">
      <AlertIsLocked v-if="dataStructure.is_locked" />
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">{{ $t("dataStructures.baseData") }}:</h5>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("dataStructures.label") }}</div>
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <b-form-input
            v-model="dataStructure.label"
            class="form-control"
            :class="{
              'is-invalid': $v.dataStructure.label.$error,
              'is-valid':
                $v.dataStructure.label.required &&
                $v.dataStructure.label.minLength
            }"
            trim
            :disabled="
              dataStructure.is_locked ||
              dataStructure.is_standard_data_structure
            "
            @input="$v.dataStructure.label.$touch()"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-3 col-form-label">
          <div class="mt-3">{{ $t("dataStructures.projects") }}</div>
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            v-model="dataStructure.projects"
            :class="{
              'is-invalid': $v.dataStructure.projects.$error,
              'is-valid': $v.dataStructure.projects.required
            }"
            class="form-control"
            :items="projects"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            :disabled="dataStructure.is_standard_data_structure"
            multiple
            @input="$v.dataStructure.projects.$touch()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertIsLocked from "@/components/DataStructures/AlertIsLocked.vue";
import { minLength, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  components: {
    AlertIsLocked
  },
  props: ["dataStructure", "isBusy"],
  computed: {
    ...mapGetters(["projects"])
  },
  validations: {
    dataStructure: {
      label: {
        required,
        minLength: minLength(3)
      },
      projects: { required, minLength: minLength(1) }
    }
  },

  methods: {
    isValid() {
      this.$v.$touch();

      const invalid = this.$v.$invalid;

      if (invalid) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("dataStructures.stepBasicsNotReady")
        });
      }
      return !invalid;
    }
  }
};
</script>
