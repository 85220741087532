<template>
  <div>
    <Wizard v-if="$route.name === 'projectDataStructuresCreate'" />
    <Details v-else-if="$route.name.includes('projectDataStructuresDetails')" />
    <Editor
      v-else-if="$route.name === 'projectDataStructuresEditor'"
      :mock-data="false"
    />
    <Table v-else />
  </div>
</template>

<script>
import Details from "@/components/DataStructures/Details/Details.vue";
import Table from "@/components/DataStructures/Table.vue";
import Editor from "@/components/DataStructures/Editor/Editor.vue";
import Wizard from "@/components/DataStructures/Wizard/Wizard.vue";

export default {
  components: { Table, Details, Editor, Wizard }
};
</script>
